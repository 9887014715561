import ApiService from './ApiService';
import axios from 'axios';

export default class CategoryService {
    fetchCategories(estateId, withTrashed, query) {
        if (withTrashed) {
            return axios.get(`${ApiService.getHost()}/categories?withTrashed=true&q=${query}&estate_id=${estateId}`, {
                headers: ApiService.getHeaders()
            });
        } else {
            return axios.get(`${ApiService.getHost()}/categories?q=${query}&estate_id=${estateId}`, {
                headers: ApiService.getHeaders()
            });
        }
    }

    fetchCategoriesTree(estateId, withTrashed, query) {
        if (withTrashed) {
            return axios.get(`${ApiService.getHost()}/categories/tree?withTrashed=true&q=${query}&estate_id=${estateId}`, {
                headers: ApiService.getHeaders()
            });
        } else {
            return axios.get(`${ApiService.getHost()}/categories/tree?q=${query}&estate_id=${estateId}`, {
                headers: ApiService.getHeaders()
            });
        }
    }

    deleteCategory(categoryId, deleteSubcategories) {
        return axios.delete(`${ApiService.getHost()}/categories/${categoryId}?ds=${deleteSubcategories}`, {
            headers: ApiService.getHeaders()
        });
    }

    restoreCategory(categoryId) {
        return axios.get(`${ApiService.getHost()}/categories/${categoryId}/restore`, {
            headers: ApiService.getHeaders()
        });
    }

    createCategory(estateId, name, short_desc, slug, iconType, icon, iconColor, parentId, listType) {
        return axios.post(`${ApiService.getHost()}/categories`, {
            estate_id: estateId,
            name: name,
            short_desc: short_desc,
            slug: slug,
            icon_type: iconType,
            icon: icon,
            icon_color: iconColor,
            parent_id: parentId,
            list_type: listType
        }, {
            headers: ApiService.getHeaders()
        });
    }
    createCategoryWithFile(fd) {
        return axios.post(`${ApiService.getHost()}/categories`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }

    fetchCategory(categoryId) {
        return axios.get(`${ApiService.getHost()}/categories/${categoryId}`, {
            headers: ApiService.getHeaders()
        });
    }

    fetchCategoryDocuments(estateId, categorySlug) {
        return axios.get(`${ApiService.getHost()}/estates/${estateId}/categories/${categorySlug}`, {
            headers: ApiService.getHeaders()
        });
    }

    updateCategory(categoryId, name, short_desc, slug, iconType, icon, iconColor, parentId, listType) {
        return axios.put(`${ApiService.getHost()}/categories/${categoryId}`, {
            name: name,
            short_desc: short_desc,
            slug: slug,
            icon_type: iconType,
            icon: icon,
            icon_color: iconColor,
            parent_id: parentId,
            list_type: listType
        }, {
            headers: ApiService.getHeaders()
        });
    }
    updateCategoryWithFile(categoryId, fd) {
        return axios.post(`${ApiService.getHost()}/categories/${categoryId}`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }
}