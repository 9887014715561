<template>
	<div class="layout-footer flex flex-column align-items-center justify-content-center">
		<div>
			<img alt="Logo" src="/images/logo.png" height="20" class="mr-2" />
			<span class="font-medium ml-2">Comunidae ©{{ year }}</span>
		</div>
		<div class="flex flex-column lg:flex-row">
			<a v-for="page in $appState.estate?.footerLinks" :key="page.id" :href="page.link" target="_blank" rel="noopener noreferrer" class="mb-2 lg:mb-0 lg:mr-4">{{ page.name }}</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
		},
		computed: {
			year() {
				return new Date().getFullYear();
			},
		}
	}
</script>