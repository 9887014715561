export default {
    getHost() {
        return process.env.NODE_ENV === 'production' ? 'https://api.comunidae.es' : 'http://127.0.0.1:8000';
    },

    getHeaders(contentType) {
        return {
            Accept: 'application/json',
            'Content-Type': contentType ?? 'application/json',
            'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`
        };
    },

    getDocumentDownloadUrl(documentId) {
        return `${this.getHost()}/documents/${documentId}/download?_token=${window.sessionStorage.getItem('token')}`;
    },

    getImageUrl(estateId, image) {
        return `${this.getHost()}/estates/${estateId}/images/${image}`;
    },

    getDocumentPdfUrl(documentId) {
        return `${this.getHost()}/documents/${documentId}/view-pdf?_token=${window.sessionStorage.getItem('token')}`;
    },
}