import ApiService from './ApiService';
import axios from 'axios';

export default class UserService {
    fetchUsers(estateId, page, roles, status, emailStatus, withTrashed, query, all) {
        let rolesParam = '';
        let withTrashedParam = '';
        let statusParam = '';
        let emailStatusParam = '';
        let allParam = '';

        if (roles) {
            roles.forEach(r => {
                rolesParam += `&roles[]=${r}`;
            });
        }

        if (withTrashed) {
            withTrashedParam = '&withTrashed=true';
        }

        if (status) {
            statusParam = `&status=${status}`;
        }

        if (emailStatus) {
            emailStatusParam = `&emailstatus=${emailStatus}`;
        }

        if (all) {
            allParam = '&a=1';
        }

        return axios.get(`${ApiService.getHost()}/users?page=${page}&estateId=${estateId}${withTrashedParam}${rolesParam}${statusParam}${emailStatusParam}&q=${query}${allParam}`, {
            headers: ApiService.getHeaders()
        });
    }

    deleteUser(userId) {
        return axios.delete(`${ApiService.getHost()}/users/${userId}`, {
            headers: ApiService.getHeaders()
        });
    }

    deleteUsers(users) {
        return axios.post(`${ApiService.getHost()}/users/delete-batch`, {
            users: users
        }, {
            headers: ApiService.getHeaders()
        });
    }

    restoreUser(userId) {
        return axios.get(`${ApiService.getHost()}/users/${userId}/restore`, {
            headers: ApiService.getHeaders()
        });
    }

    createUser(estateId, name, email, dwelling, phone, roleId, password, communications, salt, pkey, data_signature) {
        return axios.post(`${ApiService.getHost()}/users`, {
            estate_id: estateId,
            name: name,
            email: email,
            dwelling: dwelling,
            phone: phone,
            role_id: roleId,
            password: password,
            communications: communications,
            salt: salt,
            pkey: pkey,
            data_signature: data_signature
        }, {
            headers: ApiService.getHeaders()
        });
    }

    fetchUser(userId) {
        return axios.get(`${ApiService.getHost()}/users/${userId}`, {
            headers: ApiService.getHeaders()
        });
    }

    updateUser(userId, name, email, dwelling, phone, roleId, active, password, communications, salt, pkey, data_signature) {
        return axios.put(`${ApiService.getHost()}/users/${userId}`, {
            name: name,
            email: email,
            dwelling: dwelling,
            phone: phone,
            role_id: roleId,
            active: active,
            password: password,
            communications: communications,
            salt: salt,
            pkey: pkey,
            data_signature: data_signature
        }, {
            headers: ApiService.getHeaders()
        });
    }

    resendVerificationEmail(userId) {
        return axios.post(`${ApiService.getHost()}/users/${userId}/verification-notification`, {}, {
            headers: ApiService.getHeaders()
        });
    }

    fetchProfile() {
        return axios.get(`${ApiService.getHost()}/current-user`, {
            headers: ApiService.getHeaders()
        });
    }

    updateProfile(name, email, dwelling, phone, communications, salt, pkey, data_signature) {
        return axios.put(`${ApiService.getHost()}/current-user`, {
            name: name,
            email: email,
            dwelling: dwelling,
            phone: phone,
            communications: communications,
            salt: salt,
            pkey: pkey,
            data_signature: data_signature
        }, {
            headers: ApiService.getHeaders()
        });
    }

    activateUser(userId) {
        return axios.post(`${ApiService.getHost()}/users/${userId}/activate`, {}, {
            headers: ApiService.getHeaders()
        });
    }
}