<template>
    <div class="h-screen w-screen fixed flex align-items-center justify-content-center z-5" v-if="$appState.loading > 0 || $appState.routeLoading" style="background-color: rgba(239, 243, 248, .8);">
        <ProgressSpinner />
    </div>
    <router-view></router-view>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import RouteGuardService from '@/service/RouteGuardService.js';
import EstateService from '@/service/EstateService.js';
import ErrorReportService from '@/service/ErrorReportService.js';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

export default {
    estateService: null,
    errorReportService: null,
    created() {
        this.estateService = new EstateService();
        this.errorReportService = new ErrorReportService();
    },
    beforeMount() {
        this.$appState.loading += 2;

        this.estateService.fetchEstate(this.$appState.domainSlug).then(response => {
            this.$appState.estate = response.data.estate;

            if (!this.$appState.estate.extra_data) {
                this.$appState.estate.extra_data = {};
            }

            if (response.data.estate.extra_data.texts) {
                this.$appState.texts = response.data.estate.extra_data.texts;
            }
            if (!response.data.estate.extra_data.button_type) {
                this.$appState.estate.extra_data.button_type = 'button-type-1';
            }

            this.$appState.loading -= 1;
        }).catch(error => {
            console.log(error);
            this.errorReportService.sendReport(
                this.$appState.visitorId,
                window.navigator.userAgent,
                null,
                this.errorReportService.getRequestData(error.request),
                this.errorReportService.getErrorData(error.response),
            );
            this.$appState.loading -= 1;
            this.$router.replace(RouteGuardService.logout());
        });

        this.estateService.fetchIcons()
            .then(response => {
                this.$appState.loading -= 1;
                this.$appState.icons = response.data.categories;
            })
            .catch(error => {
                console.error(error);
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    null,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.$appState.loading -= 1;
            });
    },
    mounted() {
        (async () => {
            // Get the visitor identifier when you need it.
            const fp = await fpPromise;
            const result = await fp.get();
            this.$appState.visitorId = result.visitorId;
        })();
    },
    computed: {
        loading() {
            return this.$appState.loading;
        }
    }
}
</script>