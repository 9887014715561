import ApiService from './ApiService';
import axios from 'axios';

export default class EstateService {
    fetchEstate(domainSlug) {
        return axios.get(`${ApiService.getHost()}/estates/${domainSlug}`);
    }

    fetchEstateHomePage(estateId) {
        return axios.get(`${ApiService.getHost()}/estates/${estateId}/home-page`, {
            headers: ApiService.getHeaders()
        });
    }

    fetchAllImages(estate_id) {
        return axios.get(`${ApiService.getHost()}/estates/${estate_id}/images`, {
            headers: ApiService.getHeaders()
        });
    }

    deleteImage(estate_id, img) {
        return axios.delete(`${ApiService.getHost()}/estates/${estate_id}/images/${img}`, {
            headers: ApiService.getHeaders()
        });
    }

    uploadImage(estate_id, fd) {
        return axios.post(`${ApiService.getHost()}/estates/${estate_id}/images`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }

    sendForm(estateId, pageId, fd) {
        return axios.post(`${ApiService.getHost()}/estates/${estateId}/pages/${pageId}/form`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }

    updateEstate(estateId, fd) {
        return axios.post(`${ApiService.getHost()}/estates/${estateId}/settings`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }

    fetchIcons() {
        return axios.get(`https://api.iconify.design/collection?prefix=mdi`);
    }

    sendCircular(estateId, fd) {
        return axios.post(`${ApiService.getHost()}/estates/${estateId}/circular`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }
}