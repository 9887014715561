<template>
    <Toast />
    <Toast position="bottom-center" group="installToast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center mb-4">
                    <iconify-icon icon="mdi:cellphone-arrow-down-variant" style="font-size: 3rem"></iconify-icon>
                    <h4>{{slotProps.message.summary}}</h4>
                    <p>{{slotProps.message.detail}}</p>
                </div>
                <div class="grid p-fluid">
                    <div class="col-6">
                        <Button class="p-button-success" label="Sí" @click="doInstall" />
                    </div>
                    <div class="col-6">
                        <Button class="p-button-secondary" label="No" @click="doNotInstall" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
	<div class="layout-wrapper layout-static flex flex-row" :class="{ 'menu-toggle': mobileMenuActive }" @click="onWrapperClick">
        <div class="layout-sidebar">
            <AppMenu :mainMenu="mainMenu" :secondaryMenu="secondaryMenu" @menuitem-click="onMenuItemClick" @newDocument="showCreateDocumentDialog" @batchDocumentUpload="showBatchDocumentUploadDialog" />
        </div>

        <div class="layout-main-container flex-grow-1">
            <AppTopBar @menu-toggle="onMenuToggle" />
            <div class="layout-main flex flex-column align-items-center justify-content-center">
                <Breadcrumb :home="breadcrumbs.home" :model="breadcrumbs.items" :class="{ 'w-full': breadcrumbs.width === 100, 'w-1440': breadcrumbs.width === 1440 }">
                    <template #item="{item}">
                        <router-link :to="item.to" custom v-slot="{href, isActive, isExactActive}">
                            <div class="flex flex-row items-center">
                                <img v-if="item.iconType === 'image' && item.id && item.icon" :src="getIconImageUrl(item.id)" style="height: 1.6em; margin-right: .2em;" />
                                <img v-if="item.iconType === 'icon' && item.icon" :src="`https://api.iconify.design/${item.icon.split(':')[0]}/${item.icon.split(':')[1]}.svg`" style="height: 1.6em; margin-right: .2em;" />
                                <a :href="href" :class="{'active-link': isActive, 'active-link-exact': isExactActive}">{{item.label}}</a>
                            </div>
                        </router-link>
                    </template>
                </Breadcrumb>
                <router-view @updateBreadcrumbs="updateBreadcrumbs" @newDocument="showCreateDocumentDialog" @batchDocumentUpload="showBatchDocumentUploadDialog" @selectIcon="showSelectIconDialog" @refetchUsers="fetchUsers" />
            </div>
            <AppFooter />
        </div>
	</div>

    <Dialog v-model:visible="createDocumentDialog" :style="{width: '450px'}" header="Subir documento" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <FileUpload name="document[]" :customUpload="true" @uploader="fileChanged" @select="fileChanged" mode="advanced" :showUploadButton="false" chooseLabel="Buscar archivo" :accept="$appState.acceptedMimetypes" :fileLimit="1" />

            <div class="field mb-4 mt-4">
                <label for="title">Título</label>
                <InputText v-model="v$.newDocument.title.$model" id="title" type="text" class="w-full mb-2" :class="{'p-invalid': v$.newDocument.title.$error}" required />
                <div v-for="error of v$.newDocument.title.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="description">Descripción</label>
                <InputText v-model="newDocument.description" id="description" type="description" class="w-full mb-2" />
            </div>
            <div class="field mb-4">
                <label for="category">{{ $appState.texts.category }}</label>
                <TreeSelect v-model="v$.newDocument.category.$model" :options="categories" class="w-full mb-2" :class="{'p-invalid': v$.newDocument.category.$error}" required />
                <div v-for="error of v$.newDocument.category.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>

            <div class="field">
                <InputSwitch v-model="newDocument.disallowDownload" id="disallowDownload" />
                <label for="disallowDownload" class="ml-3">Impedir la descarga del documento (solo para PDF)</label>
            </div>
            <div class="field">
                <InputSwitch v-model="newDocument.pinned" id="pinned" />
                <label for="pinned" class="ml-3">Mostrar en inicio</label>
            </div>
            <div class="field">
                <InputSwitch v-model="newDocument.notify" id="notify" />
                <label for="notify" class="ml-3">Notificar a los usuarios</label>
            </div>
            <div class="field mt-2" v-if="newDocument.notify">
                <strong>Usuarios a notificar: {{ newDocument.notifyUsers.length + ' usuarios' }}</strong>
                <Button label="Seleccionar usuarios" icon="pi pi-send" :loading="working" class="p-button-rounded p-button-help mt-2" @click="selectUsersDialog = true" :disabled="working" />
            </div>

            <div class="mt-4" v-if="registerError"><small class="p-error font-bold">{{ registerError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="createDocumentDialog = false" :disabled="working" />
            <Button label="Subir documento" icon="pi pi-document-plus" :loading="working" class="p-button-text p-button-success" @click="createDocument" :disabled="canCreateDocument || working" />
        </template>
    </Dialog>
    <Dialog v-model:visible="selectUsersDialog" :style="{width: '450px'}" header="Seleccionar usuarios" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <DataTable :value="filteredUsers" v-model:selection="newDocument.notifyUsers" class="p-datatable-gridlines" dataKey="id" :rowHover="true" :loading="loading" responsiveLayout="scroll">
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                        <div class="flex flex-column md:flex-row">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="usersQuery" placeholder="Buscar..." />
                            </span>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No hay usuarios.
                </template>
                <template #loading>
                    Cargando datos, espera por favor...
                </template>
                <Column selectionMode="multiple" style="width: 3rem"></Column>
                <Column header="Nombre" field="name" style="min-width:12rem">
                    <template #body="{data}">
                        {{data.name}}
                    </template>
                </Column>
            </DataTable>
        </div>
        <template #footer>
            <Button label="Aceptar" icon="pi pi-check" :loading="working" class="p-button-text p-button-success" @click="selectUsersDialog = false" :disabled="working" />
        </template>
    </Dialog>
    <Dialog v-model:visible="selectIconDialog" :style="{width: '450px'}" header="Seleccionar icono" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="category">Categoría de iconos</label>
                <Dropdown v-model="selectedIconCategory" :options="iconCategories" class="w-full mb-2" />
            </div>
            <span class="p-input-icon-left mb-4">
                <i class="pi pi-search" />
                <InputText v-model="iconQuery" placeholder="Buscar (en inglés)..." />
            </span>
            <div class="grid">
                <div class="col-2" v-for="(icon, index) in filteredIcons" :key="index">
                    <Button type="button" @click="iconSelected(icon)" class="p-button-text"><img :src="`${iconifyApi}${icon}.svg`" style="height: 1.8em;" /></Button>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" :loading="working" class="p-button-text" @click="selectIconDialog = false" :disabled="working" />
        </template>
    </Dialog>

    <Dialog v-model:visible="batchDocumentUploadDialog" :style="{width: '450px'}" header="Subir varios documentos" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <label for="documents[]"><strong>Máximo 20. La suma del tamaño de todos los archivos no puede exceder los 300MB.</strong></label>
            <FileUpload name="documents[]" :customUpload="true" :multiple="true" @uploader="fileChangedMulti" @select="fileChangedMulti" mode="advanced" :showUploadButton="false" chooseLabel="Buscar archivo" :accept="$appState.acceptedMimetypes" :fileLimit="20" invalidFileLimitMessage="Número máximo de archivos excedido. Solo se pueden subir {0} a la vez." />

            <div class="field mb-4 mt-4">
                <label for="category">{{ $appState.texts.category }}</label>
                <TreeSelect v-model="v$.batchDocuments.category.$model" :options="categories" class="w-full mb-2" :class="{'p-invalid': v$.batchDocuments.category.$error}" required />
                <div v-for="error of v$.batchDocuments.category.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>

            <div class="mt-4" v-if="registerError"><small class="p-error font-bold">{{ registerError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="batchDocumentUploadDialog = false" :disabled="working" />
            <Button label="Subir documentos" icon="pi pi-document-plus" :loading="working" class="p-button-text p-button-success" @click="batchDocumentUpload" :disabled="canBatchDocumentUpload || working" />
        </template>
    </Dialog>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import ApiService from '@/service/ApiService.js';
import CategoryService from '@/service/CategoryService.js';
import UserService from '@/service/UserService.js';
import DocumentService from '@/service/DocumentService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';

const breadcrumbsWidths = {
    0: 100,
    1: 1150,
    2: 1440
};

export default {
    beforeRouteEnter(to) {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true && (to.path === '/' || to.path === '/home')) {
            window.sessionStorage.setItem('afterLogin', '/');
            return goTo;
        }

        return true;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            menuClick: false,
            mobileMenuActive: false,
            mainMenu: [
                {
                    label: 'Inicio',
                    icon: 'mdi:home', to: '/',
                }
            ],
            secondaryMenu: [
                {
                    separator: true
                },
                {
                    label: 'Webmail',
                    icon: 'mdi:email-fast',
                    url: 'https://webmail.ociomercado.com',
                    target: '_blank'
                },
                {
                    label: 'Mi perfil',
                    icon: 'mdi:account-edit',
                    to: '/my-profile',
                },
                {
                    label: 'Cerrar sesión',
                    icon: 'mdi:logout',
                    command: () => {
                        this.$router.replace(RouteGuardService.logout());
                    },
                },
            ],
            breadcrumbs: {
                width: breadcrumbsWidths[1],
                home: {label: ' Inicio', icon: 'mdi:home', to: '/'},
                items: [
                ]
            },
            selectUsersDialog: false,
            createDocumentDialog: false,
            batchDocumentUploadDialog: false,
            users: null,
            categories: null,
            query: '',
            usersQuery: '',
            newDocument: {
                title: '',
                description: '',
                category: '',
                file: '',
                disallowDownload: false,
                pinned: false,
                notify: false,
                notifyUsers: []
            },
            batchDocuments: {
                category: '',
                files: [],
            },
            validationMessages: {},
            loading: false,
            working: false,
            registerError: null,
            onDocumentCreated: null,
            onBatchDocumentUpload: null,
            iconifyApi: 'https://api.iconify.design/mdi/',
            selectIconDialog: false,
            iconQuery: '',
            iconCategories: [],
            selectedIconCategory: null,
            selectIconCallback: null
        }
    },
    categoryService: null,
    userService: null,
    documentService: null,
    errorReportService: null,
    selectedFile: null,
    installEvent: null,
    created() {
        this.categoryService = new CategoryService();
        this.userService = new UserService();
        this.documentService = new DocumentService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    validations() {
        return {
            newDocument: {
                title: { required },
                category: { required },
                file: { required },
                disallowDownload: { required },
                notify: { required },
                pinned: { required },
            },
            batchDocuments: {
                category: { required },
            },
        };
    },
    beforeMount() {
        window.addEventListener('appinstalled', () => {
            this.$appState.installPrompt = 0;
            window.sessionStorage.setItem('install_prompt', '0');
        });
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.installEvent = e;
        });
    },
    mounted() {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

        if (this.$appState.installPrompt > 0 && !(navigator.standalone || isStandalone)) {
            this.$toast.add({severity:'info', summary: 'Instalar', detail: '¿Quieres instalar Comunidae en tu dispositivo?', group: 'installToast', closable: false});
        }

        this.breadcrumbs.items = this.$appState.breadcrumbs;

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.setMenu();
                    this.setBg();
                    this.fetchCategories();
                    this.fetchUsers();
                },
            );
        } else {
            this.setMenu();
            this.setBg();
            this.fetchCategories();
            this.fetchUsers();
        }
    },
    computed: {
        filteredUsers() {
            if (this.usersQuery && this.usersQuery.length >= 3) {
                return this.users.filter(u => {
                    return u.name.toLowerCase().includes(this.usersQuery.toLowerCase())
                });
            }

            return this.users;
        },
        canCreateDocument() {
            return this.v$.newDocument.title.$errors.length !== 0
                || this.v$.newDocument.category.$errors.length !== 0
                || this.newDocument.title.length === 0
                || (this.newDocument.category === null || this.newDocument.category === '')
                || (this.newDocument.file === null || this.newDocument.file === '');
        },
        canBatchDocumentUpload() {
            return this.v$.batchDocuments.category.$errors.length !== 0
                || (this.batchDocuments.category === null || this.batchDocuments.category === '')
                || (this.batchDocuments.files === null || this.batchDocuments.files.length < 1);
        },
        filteredIcons() {
            let icons = [];

            if (this.selectedIconCategory) {
                if (this.iconQuery && this.iconQuery.length >= 3) {
                    icons = icons.concat(this.$appState.icons[this.selectedIconCategory].filter(i => {
                        return i.includes(this.iconQuery.trim().toLowerCase());
                    }));
                } else {
                    icons = this.$appState.icons[this.selectedIconCategory];
                }
            } else {
                if (this.iconQuery && this.iconQuery.length >= 3) {
                    this.iconCategories.forEach(iC => {
                        icons = icons.concat(this.$appState.icons[iC].filter(i => {
                            return i.includes(this.iconQuery.trim().toLowerCase());
                        }));
                    })
                } else {
                    icons = this.$appState.icons[this.iconCategories[0]];
                }
            }

            return icons;
        }
    },
    watch: {
        $route() {
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick)
                this.mobileMenuActive = false;
            
            this.menuClick = false;
        },
        onMenuToggle() {
            if (!this.menuClick) {
                this.menuClick = true;

                if (!this.isDesktop()) {
                    this.mobileMenuActive = !this.mobileMenuActive;
                }

                event.preventDefault();
            } else {
                this.mobileMenuActive = false;
                this.menuClick = false;
            }
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.mobileMenuActive = false;
            }
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        setMenu() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('user:create')) {
                this.mainMenu.push({
                    label: 'Usuarios',
                    icon: 'mdi:account-multiple', to: '/users',
                });
            }
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:create')) {
                this.mainMenu.push({
                    label: this.$appState.texts.categories,
                    icon: 'mdi:folder-multiple', to: '/categories',
                });
            }
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('page:create')) {
                this.mainMenu.push({
                    label: 'Páginas',
                    icon: 'mdi:file-table-box-multiple', to: '/pages',
                });
            }
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('document:create')) {
                this.mainMenu.push({
                    label: 'Documentos',
                    icon: 'mdi:file-document-multiple', to: '/documents',
                });
                this.mainMenu.push({
                    label: 'Subir documento',
                    icon: 'mdi:file-upload', event: 'newDocument',
                });
                this.mainMenu.push({
                    label: 'Subir varios documentos',
                    icon: 'mdi:folder-upload', event: 'batchDocumentUpload',
                });
            }

            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('estate:settings') || this.$appState.tokenAbilities.includes('estate:update')) {
                this.mainMenu.push({
                    label: 'Nueva circular',
                    icon: 'mdi:text-account', to: '/circular',
                });

                this.secondaryMenu.push({
                    label: 'Ajustes de Comunidad',
                    icon: 'mdi:wrench-cog', to: '/settings',
                });
            }
        },
        doInstall() {
            window.sessionStorage.setItem('install_prompt', '0');
            this.$toast.removeAllGroups();
            this.installEvent.prompt();
            /*this.installEvent.userChoice.then((choice) => {
                console.log(choice);

                if (choice.outcome === 'accepted') {
                // Do something additional if the user chose to install
                } else {
                // Do something additional if the user declined
                }
            });*/
        },
        doNotInstall() {
            window.sessionStorage.setItem('install_prompt', '0');
            this.$appState.installPrompt = 0;
            this.$toast.removeAllGroups();
        },
        updateBreadcrumbs(item) {
            if (item instanceof Array) {
                this.$appState.breadcrumbs = item;
            } else if (item.to !== '/') {
                const i = this.$appState.breadcrumbs.find(({to}) => to === item.to);

                if (!i) {
                    if (item.replace) {
                        this.$appState.breadcrumbs = [item];
                    } else {
                        this.$appState.breadcrumbs.push(item);
                    }
                } else if (item.replace) {
                    this.$appState.breadcrumbs = [item];
                }
            }

            this.breadcrumbs.items = this.$appState.breadcrumbs;
            window.sessionStorage.setItem('breadcrumbs', JSON.stringify(this.$appState.breadcrumbs));
            this.checkBreadcrumbsFullW();
        },
        fetchCategories() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:list')) {
                this.loading = true;
                this.categoryService.fetchCategoriesTree(this.$appState.estate.id, false, '').then(response => {
                    let ac = [];
                    this.buildTree(ac, response.data.categories[0]);
                    this.categories = ac;
                }).catch(error => {
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'C-01: No se ha podido obtener las categorías', life: 3000});
                });
            }
        },
        buildTree(a, p) {
            p.children.forEach(c => {
                let ch = {
                    key: c.id,
                    label: c.name,
                    data: {
                        id: c.id,
                        slug: c.slug,
                        name: c.name,
                        short_desc: c.short_desc,
                        extra_data: c.extra_data,
                        created_at: new Date(c.created_at)
                    },
                    children: []
                };

                if (c.children && c.children.length > 0) {
                    this.buildTree(ch.children, c);
                }

                a.push(ch);
            });
        },
        fetchUsers() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('user:list')) {
                this.loading = true;
                this.userService.fetchUsers(0, null, null, null, false, '', 1).then(response => {
                    this.users = response.data.users.filter(u => u.communications === true);
                    this.loading = false;
                }).catch(error => {
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'D-01: No se ha podido obtener los documentos', life: 3000});
                });
            }
        },
        showCreateDocumentDialog(callback) {
            this.newDocument = {
                title: '',
                description: '',
                category: this.$appState.newDocumentCategory ?? '',
                file: '',
                disallowDownload: false,
                pinned: false,
                notify: false,
                notifyUsers: []
            };
            this.registerError = null;
            this.onDocumentCreated = callback;
            this.createDocumentDialog = true;
        },
        showBatchDocumentUploadDialog(callback) {
            this.batchDocuments = {
                category: this.$appState.newDocumentCategory ?? '',
                files: [],
            };
            this.registerError = null;
            this.onBatchDocumentUpload = callback;
            this.batchDocumentUploadDialog = true;
        },
        fileChanged(event) {
            this.selectedFile = event.files[0];
            const p = this.selectedFile.name.lastIndexOf('.');
            this.newDocument.title = this.selectedFile.name.substring(0, p);
            this.newDocument.file = this.selectedFile.name;
        },
        fileChangedMulti(event) {
            this.batchDocuments.files = event.files;
        },
        createDocument() {
            if (!this.working) {
                this.working = true;
                let fd = new FormData();
                fd.append('estate_id', this.$appState.estate.id);
                fd.append('title', this.newDocument.title);
                fd.append('description', this.newDocument.description);
                fd.append('category_id', Object.keys(this.newDocument.category)[0]);
                fd.append('document', this.selectedFile);
                fd.append('disallow_download', this.newDocument.disallowDownload ? 1 : 0);
                fd.append('notify', this.newDocument.notify ? 1 : 0);
                fd.append('pinned', this.newDocument.pinned ? 1 : 0);

                if (this.newDocument.notify) {
                    this.newDocument.notifyUsers.forEach(u => {
                        fd.append('notify_users[]', u.id);
                    });
                }

                this.documentService.createDocument(fd).then(response => {
                    if (response.data.document) {
                        this.working = false;
                        this.createDocumentDialog = false;
                        this.selectedFile = null;
                        
                        if (this.onDocumentCreated) {
                            this.onDocumentCreated();
                        } else {
                            this.$router.go();
                        }
                    } else {
                        this.registerError = 'ERROR D-04: Ha ocurrido un error al subir el documento';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {
                            title: this.newDocument.title,
                            description: this.newDocument.description,
                            category_id: Object.keys(this.newDocument.category)[0],
                            notify: this.newDocument.notify ? 1 : 0,
                            pinned: this.newDocument.pinned ? 1 : 0,
                            document: this.selectedFile.name,
                            disallowDownload: this.newDocument.disallowDownload,
                            file_size: this.selectedFile.size
                        }),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.registerError = 'ERROR D-03: Ha ocurrido un error al subir el documento';
                });
            }
        },
        batchDocumentUpload() {
            if (!this.working) {
                this.working = true;
                let fd = new FormData();
                fd.append('estate_id', this.$appState.estate.id);
                fd.append('category_id', Object.keys(this.batchDocuments.category)[0]);

                this.batchDocuments.files.forEach(f => {
                    fd.append('documents[]', f);
                });

                this.documentService.batchDocumentUpload(fd).then(response => {
                    if (response.data.documents) {
                        this.working = false;
                        this.batchDocumentUploadDialog = false;
                        this.selectedFile = null;
                        
                        if (this.onBatchDocumentUpload) {
                            this.onBatchDocumentUpload();
                        } else {
                            this.$router.go();
                        }
                    } else {
                        this.registerError = 'ERROR D-06: Ha ocurrido un error al subir los documentos';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {
                            documents: this.batchDocuments.map(f => ({file_name: f.name, file_size: f.size}))
                        }),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.registerError = 'ERROR D-05: Ha ocurrido un error al subir los documentos';
                });
            }
        },
        showSelectIconDialog(callback) {
            this.iconQuery = '';
            this.selectedIconCategory = null;

            if (!this.iconCategories || this.iconCategories.length < 1) {
                this.iconCategories = Object.keys(this.$appState.icons);
            }

            this.selectIconCallback = callback;
            this.selectIconDialog = true;
        },
        iconSelected(icon) {
            this.selectIconCallback(`mdi:${icon}`);
            this.selectIconDialog = false;
        },
        setBg() {
            if (this.$appState.estate.extra_data?.app_bg) {
                let wrapper = document.querySelector('.layout-wrapper');
                wrapper.style.backgroundImage = 'url(' + ApiService.getHost() + '/estates/' + this.$appState.domainSlug + '/app-bg)';
                wrapper.style.backgroundPosition = 'center center';
                wrapper.style.backgroundSize = 'cover';
                wrapper.style.backgroundAttachment = 'fixed';
            }
        },
        checkBreadcrumbsFullW() {
            this.breadcrumbs.isFullWidth = false;
            this.breadcrumbs.width = breadcrumbsWidths[1];

            if (this.breadcrumbs.items.length > 1) {
                let tmp = this.breadcrumbs.items.slice(-1);

                if (tmp && tmp.length > 0 && tmp[0] && tmp[0].to.includes('categories/')) {
                    if (window.innerWidth < 1150) {
                        this.breadcrumbs.width = breadcrumbsWidths[0];
                    } else if (window.innerWidth < 1440) {
                        this.breadcrumbs.width = breadcrumbsWidths[0];
                    } else {
                        this.breadcrumbs.width = breadcrumbsWidths[2];
                    }
                }
            }
        },
        getIconImageUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/categories/${id}/icon-image?_token=${token}`;
        },
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './Dashboard.scss';
</style>
