<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Comunidae logo" src="/images/logo.png" />
			<span>{{ $appState.estate?.name }}</span>
		</router-link>
		<span class="p-input-icon-left w-4 hidden lg:block">
			<i class="pi pi-search" />
			<InputText v-model="searchQuery" placeholder="Buscar..." class="w-full" @keyup.enter="search" />
		</span>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden origin-top">
			<li>
				<button class="p-link layout-topbar-button" @click="searchDialog = true">
					<i class="pi pi-search"></i>
					<span>Buscar</span>
				</button>
			</li>
		</ul>
	</div>
	<Dialog v-model:visible="searchDialog" :style="{width: '450px'}" header="Buscar..." :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <InputText v-model="searchQuery" type="text" class="w-full mb-2" @keyup.enter="search" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="searchDialog = false" />
            <Button label="Buscar" icon="pi pi-search" class="p-button-text" @click="search()" />
        </template>
    </Dialog>
</template>

<script>
export default {
	emits: ['menu-toggle', 'topbar-menu-toggle', 'newDocument'],
	data() {
		return {
			searchQuery: '',
			qTimeout: null,
            searchDialog: false,
		};
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		search() {
			this.searchDialog = false;
			const q = encodeURIComponent(this.searchQuery);

			this.$router.push({name: 'search', query: { q: q }});
		}
    },
}
</script>