export default {
    getDocumentIcon: function (mimetype) {
        switch(mimetype) {
            case 'application/pdf':
                return 'mdi:file-pdf-box';
            case 'image/jpeg':
                return 'mdi:file-jpg-box';
            case 'image/png':
                return 'mdi:file-png-box';
            case 'image/gif':
                return 'mdi:file-gif-box';
            case 'application/vnd.ms-excel':
            case 'application/vnd.oasis.opendocument.text':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'mdi:file-excel';
            case 'application/msword':
            case 'application/vnd.oasis.opendocument.spreadsheet':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'mdi:file-word';
            default:
                return 'mdi:file';
        }
    }
}