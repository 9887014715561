<template>
	<div class="layout-menu-container h-full flex flex-column justify-content-between">
		<AppSubmenu :items="mainMenu" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" @newDocument="newDocument" @batchDocumentUpload="batchDocumentUpload" />
		<AppSubmenu :items="secondaryMenu" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	props: {
		mainMenu: Array,
		secondaryMenu: Array,
	},
	emits: ['menuitem-click', 'newDocument', 'batchDocumentUpload'],
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
		onKeyDown(event) {
			const nodeElement = event.target;

			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		},
		newDocument(callback) {
			this.$emit('newDocument', callback);
		},
		batchDocumentUpload(callback) {
			this.$emit('batchDocumentUpload', callback);
		}
    },
	computed: {
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>