import ApiService from './ApiService';
import HelperFunctions from '../helperFunctions';
import axios from 'axios';

export default class DocumentService {
    fetchDocuments(estateId, page, categories, withTrashed, query, orderBy, sortOrder) {
        let categoriesParam = '';

        categories.forEach(c => {
            categoriesParam += `&categories[]=${c}`;
        });

        if (withTrashed) {
            return axios.get(`${ApiService.getHost()}/documents?estate_id=${estateId}&page=${page}&withTrashed=true${categoriesParam}&q=${query}&ob=${orderBy}&so=${sortOrder}`, {
                headers: ApiService.getHeaders()
            });
        } else {
            return axios.get(`${ApiService.getHost()}/documents?estate_id=${estateId}&page=${page}&q=${query}${categoriesParam}&ob=${orderBy}&so=${sortOrder}`, {
                headers: ApiService.getHeaders()
            });
        }
    }

    deleteDocument(documentId) {
        return axios.delete(`${ApiService.getHost()}/documents/${documentId}`, {
            headers: ApiService.getHeaders()
        });
    }

    deleteDocuments(documents) {
        return axios.post(`${ApiService.getHost()}/documents/batch-delete`, {
            documents: documents
        }, {
            headers: ApiService.getHeaders()
        });
    }

    restoreDocument(documentId) {
        return axios.get(`${ApiService.getHost()}/documents/${documentId}/restore`, {
            headers: ApiService.getHeaders()
        });
    }

    createDocument(fd) {
        return axios.post(`${ApiService.getHost()}/documents`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }

    fetchDocument(documentId) {
        return axios.get(`${ApiService.getHost()}/documents/${documentId}`, {
            headers: ApiService.getHeaders()
        });
    }

    updateDocument(documentId, title, description, categoryId, disallowDownload, pinned) {
        return axios.put(`${ApiService.getHost()}/documents/${documentId}`, {
            title: title,
            description: description,
            category_id: categoryId,
            disallow_download: disallowDownload,
            pinned: pinned
        }, {
            headers: ApiService.getHeaders()
        });
    }

    updateDocumentWithFile(documentId, fd) {
        return axios.post(`${ApiService.getHost()}/documents/${documentId}`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }

    searchDocuments(estateId, page, query) {
        return axios.get(`${ApiService.getHost()}/documents?estate_id=${estateId}&page=${page}&q=${query}`, {
            headers: ApiService.getHeaders()
        });
    }

    getDocumentIcon(mimetype) {
        return HelperFunctions.getDocumentIcon(mimetype);
    }

    batchDocumentUpload(fd) {
        return axios.post(`${ApiService.getHost()}/documents/batch-upload`, fd, {
            headers: ApiService.getHeaders('multipart/form-data')
        });
    }
}