import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from './dashboard/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('./dashboard/Home.vue')
            },
            {
                path: 'users',
                name: 'index-users',
                component: () => import('./dashboard/users/IndexUsers.vue')
            },
            {
                path: 'documents',
                name: 'index-documents',
                component: () => import('./dashboard/documents/IndexDocuments.vue')
            },
            {
                path: 'categories',
                name: 'index-categories',
                component: () => import('./dashboard/categories/IndexCategories.vue')
            },
            {
                path: 'pages',
                name: 'index-pages',
                component: () => import('./dashboard/pages/IndexPages.vue')
            },
            {
                path: 'categories/:slug',
                name: 'show-category-documents',
                component: () => import('./dashboard/categories/ShowCategory.vue')
            },
            {
                path: 'pages/:slug',
                name: 'show-page-content',
                component: () => import('./dashboard/pages/Page.vue')
            },
            {
                path: 'pages/:slug/edit',
                name: 'edit-page',
                component: () => import('./dashboard/pages/PageEditor.vue')
            },
            {
                path: 'search',
                name: 'search',
                component: () => import('./dashboard/search/Search.vue'),
                props: route => ({ routeQuery: decodeURIComponent(route.query.q) })
            },
            {
                path: 'my-profile',
                name: 'my-profile',
                component: () => import('./dashboard/users/MyProfile.vue'),
            },
            {
                path: 'settings',
                name: 'settings',
                component: () => import('./dashboard/settings/Settings.vue'),
            },
            {
                path: 'circular',
                name: 'circular',
                component: () => import('./dashboard/pages/Circular.vue'),
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./auth/Login.vue')
    },
    {
        path: '/email/verify/:userId/:hash',
        name: 'email-verify',
        component: () => import('./auth/EmailVerify.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./auth/ForgotPassword.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('./auth/ResetPassword.vue')
    },
    {
        path: '/footer-links/:slug',
        name: 'footer-links',
        component: () => import('./FooterPage.vue')
    },
    {
        path: '/sign-up',
        name: 'signUp',
        component: () => import('./auth/SignUp.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
