<template>
	<nav v-if="items">
		<template v-for="(item,i) of items">
			<div v-if="visible(item) && !item.separator" :key="item.label || i" :class="[{'layout-menuitem-category': root, 'active-menuitem': activeIndex === i && !item.to && !item.disabled}]" role="none">
				<router-link v-if="item.to" :to="item.to" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :style="item.style" @click="onMenuItemClick($event,item,i)" :target="item.target" :aria-label="item.label" exact role="menuitem" v-ripple v-tooltip.right="{ value: item.label }">
					<iconify-icon :icon="item.icon"></iconify-icon>
					<Badge v-if="item.badge" :value="item.badge"></Badge>
				</router-link>
				<a v-if="item.url || item.command" :href="item.url || '#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" @click="onMenuItemClick($event,item,i)" :target="item.target" :aria-label="item.label" role="menuitem" v-ripple v-tooltip.right="{ value: item.label }">
					<iconify-icon :icon="item.icon"></iconify-icon>
					<Badge v-if="item.badge" :value="item.badge"></Badge>
				</a>
				<button v-if="item.event" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :style="item.style" @click="$emit(item.event)" :aria-label="item.label" role="menuitem" v-ripple v-tooltip.right="{ value: item.label }">
					<iconify-icon :icon="item.icon"></iconify-icon>
					<Badge v-if="item.badge" :value="item.badge"></Badge>
				</button>
			</div>
			<div class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></div>
		</template>
	</nav>
</template>
<script>
export default {
	name: 'appsubmenu',
	props: {
		items: Array,
		root: {
			type: Boolean,
			default: false
		}
	},
	emits: ['menuitem-click', 'newDocument', 'batchDocumentUpload'],
	data() {
		return {
			activeIndex : null
		}
	},
	methods: {
		onMenuItemClick(event, item, index) {
			if (item.disabled) {
				event.preventDefault();
				return;
            }

            if (!item.to && !item.url) {
                event.preventDefault();
            }

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
			}

			this.activeIndex = index === this.activeIndex ? null : index;

			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		},
		visible(item) {
			return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
		}
	}
}
</script>
