export default {
    loginUrl: '/login',

    isLoggedIn() {
        const token = window.sessionStorage.getItem('token');
        const tokenAbilities = window.sessionStorage.getItem('token_abilities');

        if (!token || !tokenAbilities) {
            return this.loginUrl;
        }

        return true;
    },

    logout() {
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('token_abilities');
        return this.loginUrl;
    }
};