import ApiService from './ApiService';
import axios from 'axios';

export default class ErrorReportService {
    getRequestData(rawRequest, data) {
        return JSON.stringify({
            url: rawRequest.responseURL,
            data: data ?? ''
        });
    }

    getErrorData(rawError) {
        return JSON.stringify({
            data: rawError.data,
            status: rawError.status,
            statusText: rawError.statusText,
        });
    }

    sendReport(visitorId, device, estate_id, requestData, errorData) {
        return axios.post(`${ApiService.getHost()}/error-reports`, {
            visitor_id: visitorId,
            device: device,
            estate_id: estate_id,
            request_data: requestData,
            error_data: errorData
        });
    }
}